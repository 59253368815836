<template>
  <div class="c-app">
    <CWrapper>
      <Header :breadcrumbs="false" />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <CCard bodyWrapper>
              <CTabs
                variant="pills"
                add-nav-classes="justify-content-center"
                :activeTab="activeTab"
              >
                <CTab
                  :title="tab.name"
                  :to="tab.route"
                  v-for="tab in tabs"
                  :key="tab.name"
                />
              </CTabs>
              <router-view class="mt-3" ref="router_view"></router-view>
            </CCard>
          </CContainer>
        </main>
      </div>
      <Footer />
    </CWrapper>
  </div>
</template>

<script>
import Header from "./partials/management/Header";
import Footer from "./partials/management/Footer";

export default {
  components: {
    Header,
    Footer
  },

  data() {
    return {
      tabs: [
        {
          route: { name: "manager.orders.new" },
          name: this.$t("manager.orders.new.title")
        },
        {
          route: { name: "manager.orders.confirmed" },
          name: this.$t("manager.orders.confirmed.title")
        },
        {
          route: { name: "manager.orders.assigned" },
          name: this.$t("manager.orders.assigned.title")
        },
        {
          route: { name: "manager.orders.in_delivery" },
          name: this.$t("manager.orders.in_delivery.title")
        },
        {
          route: { name: "manager.orders.preorders" },
          name: this.$t("manager.orders.preorders.title")
        },
        {
          route: { name: "manager.orders.finished" },
          name: this.$t("manager.orders.finished.title")
        },
        {
          route: { name: "manager.orders.canceled" },
          name: this.$t("manager.orders.canceled.title")
        },
        {
          route: { name: "manager.orders.pickup" },
          name: this.$t("manager.orders.pickup.title")
        },
        {
          route: { name: "manager.map" },
          name: this.$t("manager.map.title")
        }
      ]
    };
  },

  computed: {
    activeTab() {
      return this.tabs.findIndex(tab => tab.route.name === this.$route.name);
    }
  },

  mounted() {
    this.$watch(
      "$route",
      function() {
        this.$refs.router_view.getData();
        if (this.interval) clearInterval(this.interval);
        this.interval = setInterval(this.$refs.router_view.getData, 10000);
      },
      { immediate: true }
    );
  },

  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss">
@import "~@coreui/coreui/dist/css/coreui.min.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.loader {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  padding-top: 200px;
}
</style>
